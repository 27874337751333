import React from "react";
import AboutButton from "../common/about/AboutButton";
import Image from "next/image";
import logo from '@/public/Logo_White.png'
import { FaFacebookF, FaLinkedinIn, } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion"
import Link from "next/link";
import { useRouter } from "next/navigation";




const Footer: React.FC<{ footerRef?: React.RefObject<HTMLDivElement> }> = ({ footerRef }) => {
    const router = useRouter();

    const handleFindStay = () => {
        router.push('/feature/filter-page')
    }

    const handleBecomeHost = () => {
        const hostUrl = process.env.HOST_URL || 'https://apartment-host.webmotech.com';
        window.open(hostUrl, '_blank');
    }

    return (
        <footer className="z-50 sticky bg-primary max-[1440px]:px-10 max-[435px]:px-3 max-[672px]:px-5 pt-20 pb-12 text-white" ref={footerRef}>
            <div className="flex flex-col justify-center items-center mx-auto max-w-[80%]">
                <h2 className="w-[35%] max-[1275px]:w-[60%] max-[1600px]:w-[50%] max-[1822px]:w-[40%] max-[769px]:w-full max-[856px]:w-[80%] text-6xl text-center max-[1100px]:text-5xl">
                    Experience Comfort In Every Stay
                </h2>

                <div className="flex max-[580px]:flex-col gap-5 my-10 max-sm:w-full text-center">
                    <AboutButton label={"FIND YOUR STAY"} type={"white-outlined"} onClick={handleFindStay} />
                    <AboutButton label={"BECOME HOST"} type={"white-filled"} onClick={handleBecomeHost} />
                </div>


                <div className="flex flex-wrap justify-center items-center max-[805px]:hidden my-10 w-full font-poppins">
                    <ul className="flex justify-around gap-10 w-max font-light text-base">
                        <li><Link href="#" className="block mb-2 hover:text-secondary">Home</Link></li>
                        <li><Link href="#" className="block mb-2 hover:text-secondary">About Us</Link></li>
                        <li><Link href="#" className="block mb-2 hover:text-secondary">What We Do</Link></li>
                        <li><Link href="#" className="block mb-2 hover:text-secondary">Donate</Link></li>
                    </ul>
                </div>

                <div className="flex flex-wrap justify-between min-[806px]:hidden mt-10 mb-10 w-full font-poppins">
                    <div className="mb-6 w-full">
                        <Accordion type="single" collapsible>
                            <AccordionItem value="item-1">
                                <AccordionTrigger className="font-normal text-base">Navigation</AccordionTrigger>
                                <AccordionContent>
                                    <ul className="font-normal">
                                        <li><a href="#" className="block mb-2 hover:text-secondary">Home</a></li>
                                        <li><a href="#" className="block mb-2 hover:text-secondary">About Us</a></li>
                                        <li><a href="#" className="block mb-2 hover:text-secondary">What We Do</a></li>
                                        <li><a href="#" className="block mb-2 hover:text-secondary">Donate</a></li>
                                    </ul>
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    </div>

                </div>



                <div className="bg-white/20 mt-5 mb-10 w-full h-[1px]"> </div>

                <div className="flex sm:flex-row flex-col justify-between items-center mt-8 w-full font-poppins text-center text-sm">
                    <Image
                        loading="lazy"
                        src={logo}
                        alt="Serviced Apartments Logo"
                        width={200}
                        className="mb-4 sm:mb-0 max-[768px]:w-[150]"
                    />
                    <p className="mb-4 sm:mb-0 font-light text-lg max-[768px]:text-sm tracking-wide">
                        © {new Date().getFullYear()} Serviced Apartments. All Rights Reserved.
                    </p>
                    <div className="flex justify-center sm:justify-start gap-4">
                        <Link className="hover:bg-white p-2 border rounded-full hover:text-primary duration-300 cursor-pointer" href={"https://web.facebook.com/ServicedApartmentsLK"} target="_blank">
                            <FaFacebookF className="w-5 h-5" />
                        </Link>
                        <Link className="hover:bg-white p-2 border rounded-full hover:text-primary duration-300 cursor-pointer" href={"https://lk.linkedin.com"} target="_blank">
                            <FaLinkedinIn className="w-5 h-5" />
                        </Link>
                        <Link className="hover:bg-white p-2 border rounded-full hover:text-primary duration-300 cursor-pointer" href={"https://x.com/?lang=en"} target="_blank">
                            <FaXTwitter  className="w-5 h-5" />
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;