"use client";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import AboutPage from "@/components/about/AboutPage";
import ImageContainer from "@/components/hero/ImageContainer/ImageContainer";
import ExplorePage from "@/components/explore/ExplorePage";
import PopularLocation from "@/components/PopularLocations/PopularLocation";
import Footer from "@/components/footer/Footer";
import { FaArrowUp } from "react-icons/fa";

gsap.registerPlugin(ScrollTrigger);

export default function Home() {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const scrollListenerRef = useRef<(() => void) | null>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > 300 && !showScrollTopButton) {
        setShowScrollTopButton(true);
      } else if (scrollPosition <= 300 && showScrollTopButton) {
        setShowScrollTopButton(false);
      }
    };

    const throttledScrollHandler = () => {
      if (!scrollListenerRef.current) {
        scrollListenerRef.current = () => {
          handleScroll();
          scrollListenerRef.current = null;
        };
        requestAnimationFrame(scrollListenerRef.current);
      }
    };

    window.addEventListener("scroll", throttledScrollHandler);

    return () => {
      window.removeEventListener("scroll", throttledScrollHandler);
    };
  }, [showScrollTopButton]);

  useEffect(() => {


    ScrollTrigger.matchMedia({

      "(min-width: 769px)": () => {

        gsap.fromTo(
          ".image-container",
          { opacity: 1, yPercent: 0 },
          {
            opacity: 0.1,
            yPercent: 0,
            ease: "power1.out", 
            scrollTrigger: {
              trigger: ".image-container",
              start: "75% top",
              end: "top bottom",
              scrub: 0.9, 
              onUpdate: (self) => {
                const imageContainer = document.querySelector('.image-container') as HTMLElement | null;
                if (imageContainer) {
                  if (self.progress > 0.75) {
                    imageContainer.style.pointerEvents = 'none';
                  } else {
                    imageContainer.style.pointerEvents = 'auto';
                  }
                }
              },
            },
          }
        );

        gsap.fromTo(
          ".about-page",
          { y: 0 },
          {
            y: "0",
            ease: "power2.out",
            scrollTrigger: {
              trigger: ".about-page",
              start: "top bottom",
              end: "top top",
              scrub: true,
            },
          }
        );

        gsap.fromTo(
          ".navbar-container-main",
          { opacity: 1, yPercent: 0 },
          {
            opacity: 0,
            yPercent: -20,
            ease: "power2.out",
            scrollTrigger: {
              trigger: ".about-page",
              start: "top bottom",
              end: "top center",
              scrub: true,
              onEnter: () => {
                const navbarChildren = document.querySelectorAll('.navbar-container-main > *') as NodeListOf<HTMLElement>;
                navbarChildren.forEach((child) => {
                  child.style.pointerEvents = 'none';
                });
              },
              onLeaveBack: () => {
                const navbarChildren = document.querySelectorAll('.navbar-container-main > *') as NodeListOf<HTMLElement>;
                navbarChildren.forEach((child) => {
                  child.style.pointerEvents = 'auto';
                });
              },
            },
          }
        );

      },

      "(max-width: 768px)": () => {
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      },
    });


    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <>
      <main className="bg-primary w-full" >

        <section className="h-screen image-container">
          <ImageContainer aboutRef={aboutRef} footerRef={footerRef} />
        </section>

        <section className="bg-white w-full about-page" ref={aboutRef}>
          <AboutPage />
        </section>

        <section className="max-[769px]:relative bg-primary h-screen scroll-explore panel">
          <ExplorePage />
        </section>

        <section className="top-0 z-40 max-[769px]:relative sticky bg-white w-full min-h-screen">
          <PopularLocation />
        </section>

        {showScrollTopButton && (
          <button
            onClick={scrollToTop}
            className="right-8 bottom-8 z-[99999] fixed bg-primary shadow-lg p-4 border rounded-full focus:outline-none text-white hover:scale-110 transition-transform duration-300 transform"
            aria-label="Scroll to top"
            style={{ opacity: showScrollTopButton ? 1 : 0 }}
          >
            <FaArrowUp size={18} />
          </button>
        )}
      </main>

      <Footer footerRef={footerRef} />

    </>
  );
}
